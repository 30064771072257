import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { InfoOutlined } from '@mui/icons-material';
import React, { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { AssetGroupContext } from '../../../AssetGroup/assetGroupContextWrapper';

const LinkFormatter = ({ value, row }) => {
  const theme = useTheme();
  const { setShowManageGroupsModal } = useContext(AssetGroupContext);

  return (
    <Box sx={{ cursor: 'default' }}>
      <Tooltip title={row?.description}>
        <Link
          to={`/groups`}
          state={{ groupId: row?.id }}
          style={{ cursor: 'pointer', textDecoration: 'unset', color: theme.palette.blue.link }}
          onClick={() => setShowManageGroupsModal(false)}
        >
          <Box display={'flex'} alignItems={'center'}>
            <span>{value}</span>
            <InfoOutlined
              sx={{
                marginLeft: 'auto',
                fontSize: '1rem',
                color: row?.description ? theme.palette.text.icon : theme.palette.text.disabled,
                cursor: row?.description ? 'pointer' : 'default'
              }}
            />
          </Box>
        </Link>
      </Tooltip>
    </Box>
  );
};
export const NRAssetGroupNameLinkProvider = props => <DataTypeProvider formatterComponent={LinkFormatter} {...props} />;
